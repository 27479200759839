import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, analyticsStore, alunoStore, turmaAlunoStore, } from '@/store';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import ShowPopup from '@/components/ShowPopup.vue';
let EntregaBoletimList = class EntregaBoletimList extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.search = '';
        this.selectedItem = {};
        this.tab = 0;
        this.reforcoId = null;
        this.headers = [
            {
                text: 'Aluno',
                value: 'aluno_full_name',
                table: 'aluno',
                field: 'aluno_nome',
                show: true,
                type: 'str',
            },
            {
                text: 'Turma',
                value: 'codigo_referencia',
                table: 'turma',
                type: 'int',
                show: true,
            },
            {
                text: 'Professor',
                value: 'professor_full_name',
                table: 'professor',
                field: 'full_name',
                type: 'str',
                show: true,
            },
            {
                text: 'Livro',
                value: 'livro_nome',
                table: 'livro',
                field: 'nome',
                type: 'str',
                show: true,
            },
            {
                text: 'Ações',
                value: 'actions',
                show: true,
            },
        ];
    }
    get allowView() {
        if (userToolStore.userToolsIndexed[`Entrega de boletim`]) {
            return userToolStore.userToolsIndexed[`Entrega de boletim`][0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed[`Entrega de boletim`]) {
            return userToolStore.userToolsIndexed[`Entrega de boletim`][0]
                .allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed[`Entrega de boletim`]) {
            return userToolStore.userToolsIndexed[`Entrega de boletim`][0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed[`Entrega de boletim`]) {
            return userToolStore.userToolsIndexed[`Entrega de boletim`][0]
                .allow_delete;
        }
    }
    async imprimirBoletim(turmaAlunoId) {
        const res = await alunoStore.getBoletim(turmaAlunoId);
        if (res.data) {
            if (res.headers['content-type'].includes('html')) {
                const html = res.data;
                const w = window.open();
                w.document.write(html);
                setTimeout(() => {
                    w.print();
                }, 1000);
            }
            else {
                const uri = 'data:image/png;base64,' + res.data;
                const a = document.createElement('a');
                a.href = uri;
                a.download = 'Boletim.png';
                a.click();
            }
        }
    }
    entregaBoletim(aluno) {
        this.$swal({
            title: 'Confirma a entrega do boletim ao aluno?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                aluno.boletim_entregue = true;
                await turmaAlunoStore.updateTurmaAluno(aluno);
                this.buscar();
            }
        });
    }
    async buscar() {
        analyticsStore.executeSearchItems();
    }
    async mounted() {
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(turmaAlunoStore.getTurmaAlunoBoletins);
        analyticsStore.setHeaders(this.headers);
        // tela que nao tem defaultFilter precisa ter o buscar() aqui
        this.buscar();
    }
};
EntregaBoletimList = __decorate([
    Component({
        components: {
            GenericListItemsCardComponent,
            GenericListTotalCardComponent,
            OpenNewTabComponent,
            ShowPopup,
        },
    })
], EntregaBoletimList);
export default EntregaBoletimList;

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Entrega de boletins")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('GenericListItemsCardComponent',{attrs:{"propTableName":"list_entrega_boletins"},scopedSlots:_vm._u([{key:"item.aluno_full_name",fn:function(ref){
var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.aluno_full_name)),"propObjectId":item.aluno_id,"propToolName":"Aluno"}})]}},{key:"item.codigo_referencia",fn:function(ref){
var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.codigo_referencia)),"propObjectId":item.turma_id,"propToolName":"Turma"}})]}},{key:"item.livro_nome",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.livro_nome)+" - "+_vm._s(item.edicao)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2 warning",attrs:{"x-small":""},on:{"click":function($event){return _vm.imprimirBoletim(item.id)}}},[_vm._v("Imprimir boletim ")]),_c('v-btn',{staticClass:"ma-2 warning",attrs:{"x-small":""},on:{"click":function($event){return _vm.entregaBoletim({ 'turma_aluno_id': item.id })}}},[_vm._v("entregar boletim ")])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }